@import "variables";

.about {
  padding: 32px;

  @media screen and (min-width: $md) {
    padding: 64px;
  }

  .content {
    max-width: 980px;
    margin: 0 auto;

    h3 {
      font-size: 3em;
      text-align: center;
      border-bottom: 1px solid $coral;
      padding-bottom: 32px;
      margin-bottom: 32px;

      @media screen and (min-width: $md) {
        padding-bottom: 64px;
      }
    }

    h4 {
      font-size: 1.4em;
      font-weight: bold;
      margin-bottom: 8px;
    }

    li.party-itinerary {
      background-color: rgba($coral, 0.5);
      margin: 0 0 1px 0;
      padding: 4px;
    }

    li strong {
      color: $navy;
    }
  }
}
