@import "variables";

.posts {
  display: flex;
  justify-content: center;
  padding: 16px;

  .clickable {
    cursor: pointer;
  }

  .post {
    border: 1px solid $coral;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    margin: 32px 16px;
    max-width: 300px;
    padding: 16px;
    transition: all 0.2s ease-in-out;

    &:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }

    figure {
      border-bottom: 1px solid $coral;
      border: 0;
      margin: -16px -16px 16px -16px;
      padding: 0;
      text-align: center;

      &:before {
        display: none;
      }
    }
  }
}
