@import "https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Lato:ital,wght@0,300;0,900;1,300;1,900&display=swap";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, body {
  color: #032522;
  background-color: #fae9e2;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Lato, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5em;
}

h1, h3 {
  font-family: Abril Fatface, TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
  font-weight: bold;
  line-height: 1.1em;
}

h2 {
  font-family: Lato, Arial, Helvetica Neue, Helvetica, sans-serif;
}

p {
  margin-bottom: 8px;
}

p:last-child {
  margin-bottom: 0;
}

a {
  color: #032522;
  border-bottom: 1px solid #ff888240;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: inline-block;
}

a:hover, a:active, a:focus {
  color: #205241;
  border-bottom-color: #ff8882;
}

address {
  margin-bottom: 8px;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.strikethrough {
  text-decoration: line-through;
}

ul {
  margin-bottom: 8px;
  list-style-type: disc;
}

ul li {
  margin-bottom: 8px;
  margin-left: 32px;
}

ul.no-bullet {
  list-style-type: none;
}

ul.no-bullet li {
  margin-left: 0;
}

img {
  max-width: 100%;
  margin: 0;
  padding: 0;
  line-height: 0;
}

figure {
  border: 1px solid #ff8882;
  margin: 0;
  padding: 0;
  line-height: 0;
  position: relative;
}

figure:before {
  content: " ";
  border: 1px solid #ff8882;
  width: 100%;
  height: 100%;
  margin: 4px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.copyright {
  color: #fff;
  background-color: #032522;
  padding: 16px;
  font-size: .8em;
}

.cards {
  margin: 0 -8px 16px;
}

@media screen and (width >= 980px) {
  .cards {
    flex-wrap: nowrap;
    display: flex;
  }

  .cards .card {
    justify-content: space-around;
    align-self: center;
  }

  .cards.two-up {
    flex-wrap: wrap;
  }

  .cards.two-up .card {
    flex: 40%;
  }

  .cards.align-top .card {
    align-self: auto;
  }
}

.cards .card {
  margin: 32px 8px;
}

.cards .card-highlight {
  z-index: 1;
  border: 1px solid #ff8882;
  margin: 0 16px 0 8px;
  padding: 32px 32px 8px;
  position: relative;
}

.cards .card-highlight:before {
  content: " ";
  z-index: 0;
  border: 1px solid #ff8882;
  width: 100%;
  height: 100%;
  margin: 4px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.cards .card-highlight .card-body {
  z-index: 1;
  position: relative;
}

.content .cards {
  border-bottom: 1px solid #ff8882;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.content .cards:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

@media screen and (width >= 980px) {
  .content .cards {
    margin-bottom: 64px;
    padding-bottom: 64px;
  }
}

div.hero {
  background: #032522 url("../background.36b48ce0.jpg") 50% / cover no-repeat fixed;
  height: 100vh;
  display: flex;
  position: relative;
}

div.hero:after {
  content: " ";
  z-index: 0;
  background: linear-gradient(0deg, #000 0%, #0000 100%);
  width: 100%;
  height: 10rem;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

div.hero .content {
  text-align: center;
  z-index: 1;
  background-color: #fae9e2;
  border: 16px solid #ff8882;
  align-self: center;
  margin: 0 auto;
  padding: .4em;
  font-size: 10vw;
  line-height: 1em;
  box-shadow: 0 0 5rem #000c;
}

@media screen and (width >= 980px) {
  div.hero .content {
    font-size: 110px;
  }
}

@media screen and (width >= 1440px) {
  div.hero .content {
    font-size: 140px;
  }
}

div.hero .content h1 {
  white-space: nowrap;
  margin: .4em .8em;
}

div.hero .content h2 {
  white-space: nowrap;
  border-top: 1px solid #032522;
  border-bottom: 1px solid #032522;
  margin: .4em;
  padding: .01em 0;
  font-size: .66em;
  font-weight: 300;
  display: inline-block;
}

div.hero .content h2 sup {
  vertical-align: text-top;
  font-size: .5em;
  line-height: 1em;
  position: relative;
  top: .3em;
}

.about {
  padding: 32px;
}

@media screen and (width >= 980px) {
  .about {
    padding: 64px;
  }
}

.about .content {
  max-width: 980px;
  margin: 0 auto;
}

.about .content h3 {
  text-align: center;
  border-bottom: 1px solid #ff8882;
  margin-bottom: 32px;
  padding-bottom: 32px;
  font-size: 3em;
}

@media screen and (width >= 980px) {
  .about .content h3 {
    padding-bottom: 64px;
  }
}

.about .content h4 {
  margin-bottom: 8px;
  font-size: 1.4em;
  font-weight: bold;
}

.about .content li.party-itinerary {
  background-color: #ff888280;
  margin: 0 0 1px;
  padding: 4px;
}

.about .content li strong {
  color: #23253d;
}

.photo {
  line-height: 0;
  position: relative;
  box-shadow: 0 0 25px #0000004d;
}

.photo a {
  color: #fff;
  text-shadow: 0 0 .5em #000;
  border-bottom-width: 0;
  margin-left: -25%;
  font-size: 5vw;
  font-weight: bold;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
}

.photo a:hover, .photo a:active {
  text-shadow: 0 0 .5em #032522cc;
}

.photo img {
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.posts {
  justify-content: center;
  padding: 16px;
  display: flex;
}

.posts .clickable {
  cursor: pointer;
}

.posts .post {
  border: 1px solid #ff8882;
  max-width: 300px;
  margin: 32px 16px;
  padding: 16px;
  transition: all .2s ease-in-out;
  box-shadow: 0 0 #0003;
}

.posts .post:hover {
  box-shadow: 0 0 20px #0003;
}

.posts .post figure {
  text-align: center;
  border: 0;
  margin: -16px -16px 16px;
  padding: 0;
}

.posts .post figure:before {
  display: none;
}
/*# sourceMappingURL=site.css.map */
