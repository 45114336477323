@import "variables";

div.hero {
  display: flex;
  background: url("../img/background.jpg") 50% 50% no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: $forest;
  height: 100vh;
  position: relative;

  &::after {
    content: " ";
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    display: block;
    height: 10rem;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }

  .content {
    align-self: center;
    background-color: $cream;
    border: 16px solid $coral;
    box-shadow: 0 0 5rem rgba(0, 0, 0, 0.8);
    text-align: center;
    margin: 0 auto;
    padding: 0.4em;
    z-index: 1;
    font-size: 10vw;
    line-height: 1em;

    @media screen and (min-width: $md) {
      font-size: 110px;
    }

    @media screen and (min-width: $lg) {
      font-size: 140px;
    }

    h1 {
      margin: 0.4em 0.8em;
      white-space: nowrap;
    }

    h2 {
      border-top: 1px solid $forest;
      border-bottom: 1px solid $forest;
      display: inline-block;
      padding: 0.01em 0;
      margin: 0.4em;
      font-size: 0.66em;
      font-weight: 300;
      white-space: nowrap;
    }
    h2 sup {
      vertical-align: text-top;
      font-size: 0.5em;
      position: relative;
      top: 0.3em;
      line-height: 1em;
    }
  }
}
