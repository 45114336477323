@import "variables";

html,
body {
  background-color: $cream;
  color: $forest;
  font-family: $font-body;
  font-size: 16px;
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 1.5em;
}

h1,
h3 {
  font-family: $font-heading;
  font-weight: bold;
  line-height: 1.1em;
}

h2 {
  font-family: $font-body;
}

p {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  display: inline-block;
  color: $forest;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border-bottom: 1px solid rgba($coral, 0.25);

  &:hover,
  &:active,
  &:focus {
    color: $juniper;
    border-bottom-color: rgba($coral, 1);
  }
}

address {
  margin-bottom: 8px;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.strikethrough {
  text-decoration: line-through;
}

ul {
  margin-bottom: 8px;
  list-style-type: disc;

  li {
    margin-left: 32px;
    margin-bottom: 8px;
  }

  &.no-bullet {
    list-style-type: none;

    li {
      margin-left: 0;
    }
  }
}

img {
  max-width: 100%;
  margin: 0;
  padding: 0;
  line-height: 0;
}

figure {
  border: 1px solid $coral;
  margin: 0;
  padding: 0;
  line-height: 0;
  position: relative;

  &:before {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid $coral;
    position: absolute;
    top: 0;
    left: 0;
    margin: 4px;
  }
}

.copyright {
  background-color: $forest;
  color: $white;
  padding: 16px;
  font-size: 0.8em;
}
