@import "variables";

.cards {
  margin: 0 -8px 16px -8px;

  @media screen and (min-width: $md) {
    display: flex;
    flex-wrap: nowrap;

    .card {
      justify-content: space-around;
      align-self: center;
    }

    &.two-up {
      flex-wrap: wrap;

      .card {
        flex: 1 1 40%;
      }
    }

    &.align-top .card {
      align-self: auto;
    }
  }

  .card {
    margin: 32px 8px;
  }

  .card-highlight {
    border: 1px solid $coral;
    padding: 32px 32px 8px 32px;
    margin: 0 16px 0 8px;
    position: relative;
    z-index: 1;

    &::before {
      content: " ";
      display: block;
      border: 1px solid $coral;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: 4px;
      z-index: 0;
    }

    .card-body {
      position: relative;
      z-index: 1;
    }
  }
}

.content .cards {
  border-bottom: 1px solid $coral;
  padding-bottom: 32px;
  margin-bottom: 32px;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @media screen and (min-width: $md) {
    padding-bottom: 64px;
    margin-bottom: 64px;
  }
}
