// Colors
$coral: #ff8882;
$cream: #fae9e2;
$burgundy: #4d0011;
$forest: #032522;

$navy: #23253d;
$juniper: #205241;

$white: #fff;
$black: #000;

// Spacing
$gutter: 16px;

// Typography
$font-heading: "Abril Fatface", TimesNewRoman, "Times New Roman", Times,
  Baskerville, Georgia, serif;
$font-body: "Lato", Arial, Helvetica Neue, Helvetica, sans-serif;

// Breakpoints
$md: 980px;
$lg: 1440px;

// Helpers
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
