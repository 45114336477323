@import "variables";

.photo {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
  line-height: 0;
  position: relative;

  a {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 5vw;
    margin-left: -25%;
    line-height: 1em;
    font-weight: bold;
    border-bottom-width: 0;
    color: #fff;
    text-shadow: 0 0 0.5em rgba(0, 0, 0, 1);

    &:hover,
    &:active {
      text-shadow: 0 0 0.5em rgba($forest, 0.8);
    }
  }

  img {
    width: 100%;
  }
}
